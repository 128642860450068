<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="exportData()"
                      style="margin: 5px;"
              >
                <span class="align-middle">导出</span>
              </b-button>
            </div>

          </b-col>
        </b-row>

        <!-- Search -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col md="4">
                <modal-select
                    label="供应商"
                    type="input"
                    v-on:change="fromChildren($event,['supplier_name','supplier_id'])"
                    :params="['supplier_name','supplier_id']"
                    modalName="供应商"
                    placeholder="点击选择供应商"
                    v-model="condition.supplier_name"
                >
                </modal-select>
              </b-col>
              <b-col md="4">
                <modal-select
                    label="品牌"
                    type="input"
                    v-on:change="fromChildren($event,['brand_name','brand_id'])"
                    :params="['brand_name','brand_id']"
                    modalName="品牌名称"
                    placeholder="点击选择品牌"
                    v-model="condition.brand_name"
                >
                </modal-select>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="区域"
                    label-for="area_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="area_name"
                      size="sm"
                      v-model="condition.area_name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('reward_order_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="order_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #row-details="row">
          <brand-reward-order-use-list :reward_order_id="row.item.id"></brand-reward-order-use-list>
        </template>
        <!-- Columns -->
        <template #cell(order_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{ data.item.id }}
        </template>

        <!-- 资金池id -->
        <template #cell(reward_id)="data">
          #{{ data.item.reward_id }}
        </template>

        <!-- 状态 -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('reward_order_status', data.item.status)}`"
          >
            {{ getCodeLabel('reward_order_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- 启用 -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- 备注 -->
        <template #cell(memo)="data">
          <div :id="`memo-${data.item.id}`">
            <feather-icon icon="Edit3Icon"/>
          </div>
          <b-tooltip
              :target="`memo-${data.item.id}`"
              placement="top"
          >
            {{ data.item.memo }}
          </b-tooltip>
        </template>

        <!-- 附件 -->
        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :id="data.item.attachments"
                             :readonly="true"
          />
        </template>

        <!-- 添加时间       -->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人        -->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- 更新时间       -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人        -->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <!-- 角色为采购 -->
            <div v-if="user.role_id === 3 || user.role_id === 4">
              <!-- 状态为审核中 -->
              <b-dropdown-item v-if="data.item.status === 2">
                <feather-icon icon="ClockIcon"/>
                <span class="align-middle ml-50">审核中</span>
              </b-dropdown-item>
            </div>

            <!-- 角色为财务或管理员 -->
            <div v-if="user.role_id === 11 || user.role_id === 12 || user.role_id === 14">
              <!-- 状态为审核中 -->
              <b-dropdown-item @click="audit(data.item.id,3,'确定审核通过吗？')" v-if="data.item.status === 2">
                <feather-icon icon="CheckIcon"/>
                <span class="align-middle ml-50">审核通过</span>
              </b-dropdown-item>
              <b-dropdown-item @click="audit(data.item.id,4,'确定审核不通过吗？')" v-if="data.item.status === 2">
                <feather-icon icon="XIcon"/>
                <span class="align-middle ml-50">审核不通过</span>
              </b-dropdown-item>
            </div>


            <!-- 状态为创建 -->
            <b-dropdown-item :to="{ name: 'apps-brandrewardorder-edit', query: { id: data.item.id } }"
                             v-if="data.item.status === 1">
              <feather-icon icon="Edit2Icon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <!-- 状态为已审核 -->
            <b-dropdown-item v-if="data.item.status === 3 || data.item.status === 4">
              <feather-icon icon="CheckCircleIcon"/>
              <span class="align-middle ml-50">已审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="audit(data.item.id,2,'确定提交审核吗？')"
                             v-if="data.item.status === 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="showPeople(data.item)" >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">变更添加人</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, toRefs, onMounted, reactive} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import brandrewardorderUseList from './brandrewardorderUseList'
import brandrewardorderStore from './brandrewardorderStore'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {getUserData} from "@/auth/utils";
import brandRewardOrderUseList from "@/views/apps/brandrewardorderuse/BrandRewardOrderUseList";
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    brandRewardOrderUseList,
    ModalSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {},
      isShowCard:false,
      changePeople:''
    }
  },
  methods: {},
  setup() {
    // Register module
    if (!store.hasModule('brandrewardorder')) store.registerModule('brandrewardorder', brandrewardorderStore)

    onMounted(() => {
      state.condition = store.getters['brandrewardorder/getCondition']
    })
    const state = reactive({
      condition: {},
    })
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandrewardorder')) store.unregisterModule('brandrewardorder')
    })

    const toast = useToast()

    const changeState = function (data) {
      const state = 1 - data.state
      store.dispatch('brandrewardorder/state', {id: data.id, state: state}).then(res => {
        data.state = state
        refetchData()
      })
    }

    const audit = function (id, status, title) {
      this.$swal({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('brandrewardorder/audit', {
            id: id,
            status: status
          }).then(res => {
            if (res.data.code == 0) {
              this.$swal({
                icon: 'success',
                title: '已提交!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }else {
              this.$swal({
                icon: 'danger',
                title: '提交失败!',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            }
            //重新加载表格
            refetchData()
          })
        }
      })
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      state.condition.supplier_id=''
      state.condition.supplier_name=''
      state.condition.brand_id=''
      state.condition.brand_name=''
      state.condition.area_name=''
      state.condition.status=null
      refetchData()
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()

    }


    const advanced_search = function(){
      this.isShowCard = !this.isShowCard
    }

    const  exportData = function () {
      const condition = {...store.getters['brandrewardorder/getCondition']}
      const params = {
        search_key: searchKey.value,
        area_name: condition.area_name,
        brand_name: condition.brand_name,
        supplier_name:condition.supplier_name,
        status:condition.status!=null ? condition.status.value:null,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      }
      store
              .dispatch('brandrewardorder/exportToExcel', params)
              .then(res => {
                toast.success("导出成功")
                let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
                let filename = "资金池额度调整及使用模板-"+new Date().getTime();
                /*-------------*/
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(data);
                link.setAttribute('download',filename+'.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = brandrewardorderUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeState,
      audit,
      searchByCondition,
      resetCondition,
      fromChildren,
      ...toRefs(state),
      advanced_search,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      exportData
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
